export default defineNuxtPlugin(({ vueApp }) => {
    vueApp.config.warnHandler = (msg, instance, trace) => {
        if (msg.includes('Hydration completed but contains mismatches')) {
            // Обробка лише попереджень гідратації
            const componentName = instance?.type?.name || 'Не визначено';
            console.warn(`Hydration mismatch у компоненті: ${componentName}`);

            // Опціонально: Відправка до моніторингового сервісу
            // Sentry.captureMessage(msg, { extra: { component: componentName, trace } });
        } else {
            // Інші попередження
            console.warn('Глобальне попередження:', msg);
            console.warn('Інформація:', trace);
        }
    };
});
