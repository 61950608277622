import * as Sentry from "@sentry/nuxt";

export default defineNuxtPlugin(() => {
    window.addEventListener('error', function (event) {
        // Перевірка, чи елемент, що викликав помилку, є <script>
        if (event.target.tagName === 'SCRIPT') {
            try {
                // Створюємо об'єкт URL для скрипта
                const scriptUrl = new URL(event.target.src, window.location.href);
                const currentOrigin = window.location.origin;

                // Перевіряємо, чи скрипт знаходиться на вашому домені
                if (scriptUrl.origin === currentOrigin) {
                    console.error('Failed to load script:', event.target.src);

                    // Відправка помилки до Sentry
                    Sentry.captureException(new Error(`Failed to load script: ${event.target.src}`));

                    // Опціонально: Перезавантаження сторінки
                    // window.location.reload();
                } else {
                    // Ігнорування скриптів з інших доменів
                    console.warn('Failed to load external script:', event.target.src);
                }
            } catch (error) {
                console.error('Error processing script error:', error);
            }
        }
    }, true);

    window.addEventListener('unhandledrejection', function (event) {
        console.error('Unhandled promise rejection:', event.reason);
        // Перезавантаження сторінки
    });
});
