import { default as _91_46_46_46slug_93yQeny3mOWpMeta } from "/var/www/projects/hillceramic.se/releases/20241010130419Z/pages/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93Yq2Ys3O3H1Meta } from "/var/www/projects/hillceramic.se/releases/20241010130419Z/pages/blogg/[...slug].vue?macro=true";
import { default as indexO3nE10V5LGMeta } from "/var/www/projects/hillceramic.se/releases/20241010130419Z/pages/blogg/index.vue?macro=true";
import { default as indexTcXannQByhMeta } from "/var/www/projects/hillceramic.se/releases/20241010130419Z/pages/checkout/index.vue?macro=true";
import { default as payment8otK5MgcCxMeta } from "/var/www/projects/hillceramic.se/releases/20241010130419Z/pages/checkout/payment.vue?macro=true";
import { default as indexe5z2FUCHvJMeta } from "/var/www/projects/hillceramic.se/releases/20241010130419Z/pages/checkout/thank-you/index.vue?macro=true";
import { default as indexia7A4gTu50Meta } from "/var/www/projects/hillceramic.se/releases/20241010130419Z/pages/collections/index.vue?macro=true";
import { default as _91slug_93HRzhVnq1FmMeta } from "/var/www/projects/hillceramic.se/releases/20241010130419Z/pages/faq/[slug].vue?macro=true";
import { default as index0mp2ZQeRdqMeta } from "/var/www/projects/hillceramic.se/releases/20241010130419Z/pages/hillceramic-rabattkoder/index.vue?macro=true";
import { default as indexuEIqJTiCSWMeta } from "/var/www/projects/hillceramic.se/releases/20241010130419Z/pages/index.vue?macro=true";
import { default as indexO1lMSeT2s6Meta } from "/var/www/projects/hillceramic.se/releases/20241010130419Z/pages/login/index.vue?macro=true";
import { default as _91id_93oGICqz3E1gMeta } from "/var/www/projects/hillceramic.se/releases/20241010130419Z/pages/qr-pr/[id].vue?macro=true";
import { default as _91_46_46_46slug_937q39Vnv9zEMeta } from "/var/www/projects/hillceramic.se/releases/20241010130419Z/pages/shop/[...slug].vue?macro=true";
import { default as indexJmPk1cq7HhMeta } from "/var/www/projects/hillceramic.se/releases/20241010130419Z/pages/track/index.vue?macro=true";
export default [
  {
    name: "slug___sv",
    path: "/:slug(.*)*",
    component: () => import("/var/www/projects/hillceramic.se/releases/20241010130419Z/pages/[...slug].vue")
  },
  {
    name: "slug___da",
    path: "/da/:slug(.*)*",
    component: () => import("/var/www/projects/hillceramic.se/releases/20241010130419Z/pages/[...slug].vue")
  },
  {
    name: "blogg-slug___sv",
    path: "/blogg/:slug(.*)*",
    component: () => import("/var/www/projects/hillceramic.se/releases/20241010130419Z/pages/blogg/[...slug].vue")
  },
  {
    name: "blogg-slug___da",
    path: "/da/blogg/:slug(.*)*",
    component: () => import("/var/www/projects/hillceramic.se/releases/20241010130419Z/pages/blogg/[...slug].vue")
  },
  {
    name: "blogg___sv",
    path: "/blogg",
    component: () => import("/var/www/projects/hillceramic.se/releases/20241010130419Z/pages/blogg/index.vue")
  },
  {
    name: "blogg___da",
    path: "/da/blogg",
    component: () => import("/var/www/projects/hillceramic.se/releases/20241010130419Z/pages/blogg/index.vue")
  },
  {
    name: "checkout___sv",
    path: "/checkout",
    meta: indexTcXannQByhMeta || {},
    component: () => import("/var/www/projects/hillceramic.se/releases/20241010130419Z/pages/checkout/index.vue")
  },
  {
    name: "checkout___da",
    path: "/da/checkout",
    meta: indexTcXannQByhMeta || {},
    component: () => import("/var/www/projects/hillceramic.se/releases/20241010130419Z/pages/checkout/index.vue")
  },
  {
    name: "checkout-payment___sv",
    path: "/checkout/payment",
    meta: payment8otK5MgcCxMeta || {},
    component: () => import("/var/www/projects/hillceramic.se/releases/20241010130419Z/pages/checkout/payment.vue")
  },
  {
    name: "checkout-payment___da",
    path: "/da/checkout/payment",
    meta: payment8otK5MgcCxMeta || {},
    component: () => import("/var/www/projects/hillceramic.se/releases/20241010130419Z/pages/checkout/payment.vue")
  },
  {
    name: "checkout-thank-you___sv",
    path: "/checkout/thank-you",
    meta: indexe5z2FUCHvJMeta || {},
    component: () => import("/var/www/projects/hillceramic.se/releases/20241010130419Z/pages/checkout/thank-you/index.vue")
  },
  {
    name: "checkout-thank-you___da",
    path: "/da/checkout/thank-you",
    meta: indexe5z2FUCHvJMeta || {},
    component: () => import("/var/www/projects/hillceramic.se/releases/20241010130419Z/pages/checkout/thank-you/index.vue")
  },
  {
    name: "collections___sv",
    path: "/kollektioner",
    component: () => import("/var/www/projects/hillceramic.se/releases/20241010130419Z/pages/collections/index.vue")
  },
  {
    name: "collections___da",
    path: "/da/samlinger",
    component: () => import("/var/www/projects/hillceramic.se/releases/20241010130419Z/pages/collections/index.vue")
  },
  {
    name: "faq-slug___sv",
    path: "/faq/:slug()",
    component: () => import("/var/www/projects/hillceramic.se/releases/20241010130419Z/pages/faq/[slug].vue")
  },
  {
    name: "faq-slug___da",
    path: "/da/faq/:slug()",
    component: () => import("/var/www/projects/hillceramic.se/releases/20241010130419Z/pages/faq/[slug].vue")
  },
  {
    name: "hillceramic-rabattkoder___sv",
    path: "/hillceramic-rabattkoder",
    component: () => import("/var/www/projects/hillceramic.se/releases/20241010130419Z/pages/hillceramic-rabattkoder/index.vue")
  },
  {
    name: "hillceramic-rabattkoder___da",
    path: "/da/hillceramic-rabattkoder",
    component: () => import("/var/www/projects/hillceramic.se/releases/20241010130419Z/pages/hillceramic-rabattkoder/index.vue")
  },
  {
    name: "index___sv",
    path: "/",
    component: () => import("/var/www/projects/hillceramic.se/releases/20241010130419Z/pages/index.vue")
  },
  {
    name: "index___da",
    path: "/da",
    component: () => import("/var/www/projects/hillceramic.se/releases/20241010130419Z/pages/index.vue")
  },
  {
    name: "login___sv",
    path: "/login",
    component: () => import("/var/www/projects/hillceramic.se/releases/20241010130419Z/pages/login/index.vue")
  },
  {
    name: "login___da",
    path: "/da/login",
    component: () => import("/var/www/projects/hillceramic.se/releases/20241010130419Z/pages/login/index.vue")
  },
  {
    name: "qr-pr-id___sv",
    path: "/qr-pr/:id()",
    component: () => import("/var/www/projects/hillceramic.se/releases/20241010130419Z/pages/qr-pr/[id].vue")
  },
  {
    name: "qr-pr-id___da",
    path: "/da/qr-pr/:id()",
    component: () => import("/var/www/projects/hillceramic.se/releases/20241010130419Z/pages/qr-pr/[id].vue")
  },
  {
    name: "shop-slug___sv",
    path: "/shop/:slug(.*)*",
    component: () => import("/var/www/projects/hillceramic.se/releases/20241010130419Z/pages/shop/[...slug].vue")
  },
  {
    name: "shop-slug___da",
    path: "/da/shop/:slug(.*)*",
    component: () => import("/var/www/projects/hillceramic.se/releases/20241010130419Z/pages/shop/[...slug].vue")
  },
  {
    name: "track___sv",
    path: "/track",
    meta: indexJmPk1cq7HhMeta || {},
    component: () => import("/var/www/projects/hillceramic.se/releases/20241010130419Z/pages/track/index.vue")
  },
  {
    name: "track___da",
    path: "/da/track",
    meta: indexJmPk1cq7HhMeta || {},
    component: () => import("/var/www/projects/hillceramic.se/releases/20241010130419Z/pages/track/index.vue")
  }
]