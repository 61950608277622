<script setup>
import {useError} from '#app';
import Cart from "~/components/organisms/Cart.vue";
import {useCustomFetch} from "~/composables/customFetch.js";
import {useStartupStore} from "~/store/sturtupStore";
import {useUserStore} from "~/store/userStore.js";
import {useRouter} from "vue-router";

const router = useRouter();
const userStore = useUserStore();
const startupStore = useStartupStore();
const error = useError();

// router.onError((error, to) => {
//   if (error.message.includes('Failed to fetch dynamically imported module') || error.message.includes("Importing a module script failed") || error.message.includes('Failed to resolve import')) {
//     window.location.reload()
//   }
// })

onErrorCaptured((err) => {
  error.value = err;
  return false;
});

const {finish: finishNuxtLoading} = useLoadingIndicator()
finishNuxtLoading()
if (process.client) {
  finishNuxtLoading()
}

const {data} = await useCustomFetch('startup')
const {
  header_right,
  header_left,
  side_menu,
  top_menu,
  side_menu_collections,
  main_menu_footer,
} = data.value.menu

startupStore.countries = data.value.countries
startupStore.header_left = header_left
startupStore.header_right = header_right
startupStore.side_menu = side_menu
startupStore.top_menu = top_menu
startupStore.side_menu_collections = side_menu_collections
startupStore.main_menu_footer = main_menu_footer
startupStore.campaign = data.value.campaign

if (data.value.user) {
  userStore.setUser(data.value.user)
}

onMounted(() => {
  window.adrecord = function (order_id, order_value, coupon_code) {
    !(function (a, d, r, e, c) {
      adrecord = {
        "programID": 1002,
        "orderID": order_id,
        "orderValue": order_value,
        "couponCode": coupon_code
      };
      e = a.createElement(d);
      e.async = !0;
      e.src = r;
      c = a.getElementsByTagName(d)[0];
      c.parentNode.insertBefore(e, c);
    })(document, "script", "https://t.adii.io/convert.js");
  }
})

</script>

<template>
  <div>
    <NuxtLayout>
      <NuxtLoadingIndicator color="#fcbf00"/>
      <Cart/>

      <NuxtPage :keepalive="{max:4,  exclude: 'payment'}"/>
    </NuxtLayout>
  </div>
</template>
