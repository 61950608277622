// plugins/blockadblock.client.js

import 'blockadblock'

export default defineNuxtPlugin(() => {
    const bab = new BlockAdBlock({
        checkOnLoad: true,
        resetOnEnd: true
    });

    bab.onDetected(() => {
        console.warn('Adblock detected!');
        // Можна показати повідомлення користувачу
    });

    bab.onNotDetected(() => {
        console.log('Adblock not detected!');
    });
});
