export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.hook('app:chunkError', () => {
        handleReloadOnChunkError();
        console.log('Network Status:', navigator.onLine ? 'Online' : 'Offline');
        if (typeof window !== 'undefined') {
            console.log('Current Route:', window.location.href);
            console.log('Browser Info:', navigator.userAgent);
            console.log('Operating System:', navigator.platform);
            console.log('Timestamp:', new Date().toISOString());
            console.log('Network Status:', navigator.onLine ? 'Online' : 'Offline');

            // Перевірка кешу
            if ('caches' in window) {
                console.log('Caches Available:', caches ? 'Yes' : 'No');
            }

            // Перевірка службових робітників
            if ('serviceWorker' in navigator) {
                console.log('Service Workers:', navigator.serviceWorker.controller ? 'Active' : 'None');
            }
        }

        console.log('Chunk error occurred');
    });

    nuxtApp.vueApp.config.errorHandler = (error, vm, info) => {
        console.error('Captured in errorHandler:', error);
        handleReloadOnFrontendError();
    };

    // Обробник для невідловлених promise-ів
    if (process.client) {
        window.addEventListener('unhandledrejection', (event) => {
            console.error('Unhandled rejection:', event.reason);
            handleReloadOnFrontendError();
        });
    }

    function handleReloadOnChunkError() {
        const reloadKey = 'chunkErrorReloaded';

        // Перевіряємо, чи перезавантажували вже сторінку
        if (!sessionStorage.getItem(reloadKey)) {
            // Позначаємо, що сторінка перезавантажена
            sessionStorage.setItem(reloadKey, 'true');

            // Перезавантажуємо сторінку
            window.location.reload();
        } else {
            sessionStorage.removeItem(reloadKey);
            console.warn('Already reloaded once due to chunk error, not reloading again.');
        }
    }

    function handleReloadOnFrontendError() {
        const reloadKey = 'frontendErrorReloaded';

        // Перевіряємо, чи перезавантажували вже сторінку після помилки на фронті
        if (!sessionStorage.getItem(reloadKey)) {
            // Позначаємо, що сторінка перезавантажена після помилки
            sessionStorage.setItem(reloadKey, 'true');
            // Перезавантажуємо сторінку
            window.location.reload();
        } else {
            sessionStorage.removeItem(reloadKey);
            console.warn('Already reloaded once due to frontend error, not reloading again.');
        }
    }
});
